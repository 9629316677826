/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
// import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import StripeCheckout from 'react-stripe-checkout';
// import {PaymentElement} from '@stripe/react-stripe-js';
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { useParams } from "react-router";
import { GlobalContext } from "../../../context/GlobalContext";
import { API, AXIOS_METHOD_TYPES, RESOURCE_STATUS, TIPS } from "../../../configs/constants";
import Vimeo from "@u-wave/react-vimeo";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { timeSince, getCampaignShareableURL,getLetterInitials } from '../../../configs/utility';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { getRecipientName } from '../../../configs/utility';
import moment from 'moment';
import "./DonorCampaignDetails.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Meta from "../../common/meta/Meta";
import ProgressBar from '../../../components/progressBar/ProgressBar';
import { useHistory } from 'react-router-dom';

let isAccordionClose;
let currentAccordienID;
let timeoutTemp;
let stripePromise;

const getStripe = () => {
  const pub_key = "pk_test_Cqq3spRmmgTA5bbyL4VawRLd" // Test Publishable Key
  // const pub_key = "pk_live_DYVDRhwwGFy0WdtWYuwd74AO" // Live Publishable Key
  // const pub_key = "pk_test_nQsocdwas51hX0KqrF57MPQv00qaUqnPmB" // Himanshu Test Publishable Key

  if (!stripePromise) {
    stripePromise = loadStripe(pub_key);
  }

  return stripePromise;
};

const DonorCampaignDetails = (props) => {

  const history = useHistory()
  const globalContext = useContext(GlobalContext);
  const [campaignData, setCampaignData] = useState(null);
  const [chatList, setChatList] = useState(null);
  const { id, paymentStatus } = useParams();
  const [showDonateBtn, setShowDonateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [chatDataMsg, setChatDataMsg] = useState(null);
  const [sendMsgText, setSendMsgText] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [chatMsg, setChatMsg] = useState("Loading...");
  const [requestorPaymentOptions, setRequestorPaymentOptions] = useState([]);
  const myUserID = globalContext.user.User.Id;
  const [chatUsers, setChatUsers] = useState(null);
  const [donatAmount, setDonatAmount] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isCommited, SetIsCommited] = useState(false);
  const [favoriteCompaigns, setFavoriteCompaign] = useState(false);

  // useEffect(() => {
  //   if (paymentStatus == 'success') {
  //     let result = campaignData.Resources.filter(resource => resource.Id === id).shift();
  //     let serviceParams = {
  //       api: API.DONATE_WITHOUT_CHAT,
  //       method: AXIOS_METHOD_TYPES.POST,
  //       params: {
  //         "CampaignId": result.CampaignId,
  //         "Resources": 
  //         [
  //             {
  //                 "CampaignResourceId": result.Id,
  //                 "Quantity": parseInt(donatAmount),
  //                 "IsAnonymous":isAnonymous
  //             }
  //         ]
  //       },
  //     };

  //     if (requestorPaymentOptions.length > 0) {
  //       if(!isCommited) {
  //           props.commonAPIRequest(serviceParams, (result) => {
  //               SetIsCommited(!isCommited);
  //               if (result && result.Status.StatusId === 1) {
  //                 getCampaignDetails(true);
  //                 globalContext.setNotifications({
  //                   type: "success",
  //                   text: "amount commited",
  //                 });
  //               }
  //           })
  //         } else {
  //           globalContext.setNotifications({
  //             type: "error",
  //             text: "You Just committed the ammount, please refresh"
  //           });
  //         }
  //     } else {
  //       globalContext.setNotifications({
  //         type: "info",
  //         text: "Payment link is not ready yet. Please check again soon."
  //       });
  //     }
  //     // globalContext.setNotifications({
  //     //   type: "success",
  //     //   text: "Payment Done Successfully!",
  //     // });
  //   }

  //   if (paymentStatus == 'canceled') {
  //     window.close();
  //     globalContext.setNotifications({
  //       type: "error",
  //       text: "Payment Cancelled!",
  //     });
  //   }
  //   if (paymentStatus == 'success') {
  //     globalContext.setNotifications({
  //       type: "success",
  //       text: "Payment Successful!",
  //     });
  //   }
  // }, []);

  useEffect(() => {
    getCampaignDetails();
    getChatList();
    getFavouriteCampaignDetails();
    const donationData = JSON.parse(localStorage.getItem("donationData"));
    if (paymentStatus == 'success' && donationData) {
      let serviceParams = {
        api: API.DONATE_WITHOUT_CHAT,
        method: AXIOS_METHOD_TYPES.POST,
        params: {
          "CampaignId": donationData.CampaignId,
          "Resources": 
          [
              {
                  "CampaignResourceId": donationData.CampaignResourceId,
                  "Quantity": donationData.Quantity,
                  "IsAnonymous":donationData.IsAnonymous
              }
          ]
        },
      };

      props.commonAPIRequest(serviceParams, (result) => {
        SetIsCommited(!isCommited);
        if (result && result.Status.StatusId === 1) {
          getCampaignDetails(true);
          globalContext.setNotifications({
            type: "success",
            text: "Payment Successful!",
          });
        }
        localStorage.removeItem("donationData");
      })

    }else if (paymentStatus == 'canceled') {
      // window.close();
      localStorage.removeItem("donationData");
      globalContext.setNotifications({
        type: "error",
        text: "Payment Cancelled!",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUrlValid = (userInput)=> {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}

  const validURL = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  const validURL2 = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

  const getUserPaymentOptions = (_campaignData) => {
    let serviceParams = {
      api: `${API.USER_PAYMENT_OPTIONS + _campaignData.RequesterId}`,
      method: AXIOS_METHOD_TYPES.GET,
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      const {
        Data,
        Status: { StatusId, Message },
      } = result;
      globalContext.setLoading(false);
      if (result && StatusId === 1) {
        setRequestorPaymentOptions(Data);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: Data ? Message : "Not able to find any payment option for the Requester",
        });
      }
    });
  };

  const getCampaignDetails = (isNotLoading) => {
    let serviceParams = {
      api: `${API.CAMPAIGN_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    if (!isNotLoading) {
      globalContext.setLoading(true);
    }
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        result.Data.Resources.map((item) => { item.selectedQuantity = item.PendingQuantity; return null; });
        setCampaignData(result.Data);
        setTimeout(() => { getUserPaymentOptions(result.Data); }, 500);
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: result ? result.Status.Message : "No data found",
      });
    });
  };

  const getChatList = () => {
    let serviceParams = {
      api: `${API.GET_CHAT_LIST}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setChatList(result.Data);


        // CODE TO OPEN THE FIRST CHAT BY DEFAULT
        let _currentId = result.Data.length ? result.Data[0].Id : null;
        if (_currentId) {
          setCurrentId(_currentId);
          currentAccordienID = _currentId;
          setChatDataMsg(null);
          getChatDataMsg();
          setSendMsgText('');
        }
        return;
      }
    });
  };

  const callSaveFavouriteCampaignAPI = (params) => {
    let serviceParams = {
      api: API.SAVE_FAVOURITE_CAMPAIGN,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
       
      } else {
       
      }
    },true);
  };

  
const getFavouriteCampaignDetails = () => {
   
    let serviceParams = {
      api: `${API.GET_FAVOURITE_CAMPAIGN}?campaignId=${id}`,
      method: AXIOS_METHOD_TYPES.GET
    };

    props.commonAPIRequest(serviceParams, (result) => {
      // // debugger
      if (result && result.Status.StatusId === 1) {
       if(result.Data.length &&result.Data[0].IsFavourite)
       {
        setFavoriteCompaign(result.Data[0].IsFavourite)
       }
      }
    },true);
  };


  const handleSelection = async (e, index) => {
    let _campaignData = JSON.parse(JSON.stringify(campaignData));
    _campaignData.Resources[index].isSelected = e.target.checked;
    _campaignData.Resources[index].selectedQuantity = _campaignData.Resources[index].PendingQuantity;

    let totalSelected = _campaignData.Resources.filter((item) => item.isSelected);
    totalSelected.length ? setShowDonateBtn(true) : setShowDonateBtn(false);

    setCampaignData(_campaignData);
  }

  // const handleDonate = () => {
  //   let _resources = [];
  //   campaignData.Resources.forEach((resource) => {
  //     if (resource.isSelected) {
  //       _resources.push({
  //         CampaignResourceId: resource.Id,
  //         Quantity: resource.selectedQuantity
  //       })
  //     }
  //   })

  //   let serviceParams = {
  //     api: API.STRIPE_PAYMENT,
  //     method: AXIOS_METHOD_TYPES.POST,
  //     params: {
  //       "resourceID": id,
  //       "resourceName": "Stripe Campaign",
  //       "amount": "100"
  //     }
  //   };

  //   globalContext.setLoading(true);
  //   props.commonAPIRequest(serviceParams, (result) => {
  //     globalContext.setLoading(false);
  //     console.log('payment response: ', result);
  //     window.open(result.Data);
  //   });
  // }

  const handleDonate = () => {
    let _resources = [];
    campaignData.Resources.forEach((resource) => {
      if (resource.isSelected) {
        _resources.push({
          CampaignResourceId: resource.Id,
          Quantity: resource.selectedQuantity
        })
      }
    })

    let serviceParams = {
      api: API.CHAT_START,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        "CampaignId": id,
        "Resources": _resources
      },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        let _campaignData = JSON.parse(JSON.stringify(campaignData));
        _campaignData.Resources.forEach((item) => {
          if (item.isSelected) {
            document.getElementById(item.Id).checked = false;
          }
        })
        getCampaignDetails();
        setShowDonateBtn(false);
        getChatList();
        return;
      }
    });
  }


  const onAccordinChange = (event) => {
    if (event.length && currentId !== event[0]) {
      isAccordionClose = false;
      setCurrentId(event[0]);
      currentAccordienID = event[0]
      setChatDataMsg(null);
      getChatDataMsg();
      setSendMsgText('');
    } else {
      clearInterval(timeoutTemp);
      isAccordionClose = true;
      setCurrentId([]);
    }
  }

  const getChatDataMsg = () => {
    if (timeoutTemp) {
      clearInterval(timeoutTemp);
    }
    let currentURL = window.location.hash
    let found = currentURL.includes("#/donor-campaign-details");
    if (found && !isAccordionClose) {
      let serviceParams = {
        api: `${API.GET_CHAT_MESSAGES}${currentAccordienID}`,
        method: AXIOS_METHOD_TYPES.GET,
      };
      setChatMsg("Loading...")
      props.commonAPIRequest(serviceParams, (result) => {
        if (result && result.Status.StatusId === 1) {
          if (result.Data.ChatMessages.length === 0) {
            setChatMsg("No Message Found")
          }
          if (result.Data.ChatMessages.length && result.Data.ChatMessages[0].ChatId === currentAccordienID) {
            setChatUsers(result.Data.ChatUsers);
            setChatDataMsg(result.Data.ChatMessages);
            timeoutTemp = setInterval(() => {
              getChatDataMsg()
            }, 20000);
          }
          return;
        }
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "No data found",
        });
      });
    } else {
      return
    }
  }

  const onSendBtnTap = () => {
    if (sendMsgText && currentId) {
      let serviceParams = {
        api: API.NEW_MESSAGE,
        method: AXIOS_METHOD_TYPES.POST,
        params: {
          "ChatId": currentId,
          "Message": sendMsgText
        },
      };

      globalContext.setLoading(true);
      props.commonAPIRequest(serviceParams, (result) => {
        globalContext.setLoading(false);
        if (result && result.Status.StatusId === 1) {
          getChatDataMsg()
          setSendMsgText('');
          return;
        }
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "No data found",
        });
      });
    }
  }

  const sendFeedbackMessage = (feedback) => {
    delete feedback.CreatedOn;
    delete feedback.Id;
    delete feedback.$id;
    delete feedback.Name;
    feedback['UserId'] = myUserID
    feedback['RequesterId'] = campaignData.RequesterId;
    let serviceParams = {
      api: API.SEND_FEEDBACK_MESSAGE,
      method: AXIOS_METHOD_TYPES.POST,
      params: feedback
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        hideModal();
        globalContext.setNotifications({
          type: "success",
          text: result ? result.Status.Message : "Feedback sent successfully.",
        });
      } else {
        hideModal();
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  }

  const hideModal = () => {
    document.getElementById("exampleModal").style.display = "none";
    document.getElementsByClassName("modal-backdrop")[0].style.display = "none";
  }
  const handleIsAnonymous=()=>{
    setIsAnonymous(!isAnonymous)
  }

  const onTextChange = (event) => {
    setSendMsgText(event.target.value);
  }

  const handleQuantityChange = (quantity, index) => {
    let _campaignData = JSON.parse(JSON.stringify(campaignData));
    _campaignData.Resources[index].selectedQuantity = parseInt(quantity);
    setCampaignData(_campaignData);
  }

  // const onToken = (token) => {
  //   fetch('/save-stripe-token', {
  //     method: 'POST',
  //     body: JSON.stringify(token),
  //   }).then(response => {
  //     response.json().then(data => {
  //       alert(`We are in business, ${data.email}`);
  //     });
  //   });
  // }

  const userPayents = async (id) => {

    let _resources = [];
    campaignData.Resources.forEach((resource) => {
      if (resource.isSelected) {
        _resources.push({
          CampaignResourceId: resource.Id,
          Quantity: resource.selectedQuantity
        })
      }
    })

    console.log(campaignData);
    let campData = campaignData.Resources.filter(resource => resource.Id === id).shift();

    let serviceParams = {
      api: API.STRIPE_PAYMENT,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        "resourceID": campData.CampaignId,
        "resourceName": campaignData.Name,
        "amount": parseInt(donatAmount) * 100
      }
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, async (result) => {
      console.log('payment response: ', result);
      const checkoutOptions = {
        sessionId: result.Data
      };
      const donationData = {
        "CampaignId": campData.CampaignId,
        "CampaignResourceId": campData.Id,
        "Quantity": parseInt(donatAmount),
        "IsAnonymous":isAnonymous
      }
      localStorage.setItem("donationData", JSON.stringify(donationData));
      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout(checkoutOptions)
      if(error){
        localStorage.removeItem("donationData");
        globalContext.setNotifications({
          type: "error",
          text: "Something went wrong, Please try again.",
        });
      }
    });
  }

  
  return (
    <div className="container campaign-details-container donor-camp-details">
      <Meta title={"Donor-Campaign-details"} content={"Donor campaign detail"} />
      {campaignData && <div className="row">
        <div className="col-12">

          {/* <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">EveryHumanNeeds Use Tips</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div dangerouslySetInnerHTML={{ __html: TIPS }}></div>
                </div>

              </div>
            </div>
          </div> */}
          <div class="modal fade" id="exampleModal2" tabindex="2" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  {/* <h5 class="modal-title" id="exampleModalLabel">Please select the amount you would like to give and a giving method from the listed options</h5> */}
                  {/*<h5 class="modal-title" id="exampleModalLabel">Thank you for your commitment. Please use one of the given method from the listed options to honor your commitment.</h5>*/}
                  <h5 class="modal-title" id="exampleModalLabel">We appreciate your generosity. Please note: 100% of donation goes to recipient. Gifts to individuals are not tax-deductible. To proceed, copy the recipient’s giving information and paste it in the respective applications - CashApp, PayPal, Venmo, Zelle. <br/>Check your inbox for commitment information.</h5>
           
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {(requestorPaymentOptions && requestorPaymentOptions.length) ? <div>
                    {requestorPaymentOptions.map((item, index) => (
                      <div
                        key={index}
                        style={{ marginBottom: 30, }}
                        onClick={() => {
                          if (isUrlValid(item.PaymentUrl)) {
                            window.open(item.PaymentUrl, '_blank');
                          }
                        }}
                      >
                        <img style={{ width: 100, marginLeft: 10, resize: "vertical" }} src={item.PaymentPlatformLogo} alt="" />{": "}
                        <span style={isUrlValid(item.PaymentUrl) ? { color: '#728FCE',cursor: 'pointer' } : { color: 'black' }}>{item.PaymentUrl}</span>
                      </div>
                    ))}
                  </div> : null}
                </div>
                <div className="modal-footer">
                <p><span style={{color:'black',fontWeight:'600'}}>Note:</span> Put a EHN memo on your donation to allow the recipient to know where the donation is coming from when they see it in their account. </p>
              </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="exampleModal3" tabindex="3" role="dialog" aria-labelledby="exampleModalLabel3" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  {/* <h5 class="modal-title" id="exampleModalLabel">Please select the amount you would like to give and a giving method from the listed options</h5> */}
                  {/*<h5 class="modal-title" id="exampleModalLabel">Thank you for your commitment. Please use one of the given method from the listed options to honor your commitment.</h5>*/}
                  <h5 class="modal-title" id="exampleModalLabel">By checking this box your name is not visible to the recipients on the EveryHumanNeeds site, but monetary donations are done through third party apps and may not be anonymous.</h5>
           
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                
                </div>
              </div>
            </div>
          </div>

          <div className="form-div details-page-holder  pt-30">
            <div className="row ">

              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3 mb-lg-0">
                <h3 className="dash-heading mb-0">{campaignData.Name} Details</h3>
              </div>

              <div className={"col-sm-12 col-md-7 col-lg-7 col-xl-7"}>
                <div className={"row ml-5"}>
                  {/* Campaign Status */}
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex  p-0 mb-4 mb-lg-0">
                    <div className='row d-flex align-items-center w-100'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-5 no-wrap m-0"> <strong>Campaign Status:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <label className={`m-0 chips-box-btn no-wrap ${campaignData && campaignData.Status === 2 ? "grn-bg" : ""}`} > {campaignData && campaignData.StatusName} </label>
                      </div>
                    </div>
                  </div>

                  {/* Campaign End Date */}
                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex mb-3 mb-lg-0 p-0">
                    <div className='row d-flex align-items-center'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-2 no-wrap m-0"> <strong>End date:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="day-text font-weight-normal m-0"> {moment(new Date(campaignData.EndDate)).format('MM/DD/YYYY')}</p>
                      </div>
                    </div>
                  </div>

                  {/* Tips */}
                  {/* <div class='col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex mb-3 mb-lg-0 p-0'>
                    <div className='row d-flex align-items-center'>
                      <p className="m-0 mr-2"><b>Tips:</b></p>
                      <button type="button" class="btn table-sitebtn no-wrap tips-btn " data-toggle="modal" data-target="#exampleModal"> <i className="fa fa-info" /> </button>
                    </div>
                  </div> */}
                </div>
              </div>


            </div>
            <div className="row ">
              <div className="col-sm-0 col-md-0 col-lg-5 col-xl-5  mb-lg-0">
              </div>
              <div className={"col-sm-0 col-md-0 col-lg-7 col-xl-7"}>
                <div className={"row ml-5"}>
                  <div className="col-sm-0 col-md-0 col-lg-6 col-xl-6 d-flex  p-0  mb-lg-0">
                  </div>
                  <div className="col-sm-0 col-md-0 col-lg-4 col-xl-4 d-flex mb-lg-0 p-0">
                  </div>
                  <div class='col-sm-0 col-md-0 col-lg-2 col-xl-2 d-flex mb-3 mb-lg-0 p-0'>
                    <div className='row d-flex align-items-center'>
                      <p className="m-0 mr-2 cursor"><b></b></p>
                      <i className={"fa fa-heart-icon cursor "+(favoriteCompaigns?'fa-heart':'fa-heart-o')} 
                      style={{marginLeft:'32px',padding:'15px'}}
                      onClick={()=>{
                        // // debugger
                        var body={
                          CampaignId:id,
                          IsFavourite:!favoriteCompaigns
                        }
                        callSaveFavouriteCampaignAPI(body)
                              setFavoriteCompaign(!favoriteCompaigns)
                     }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11  mb-3 mb-lg-0 p-0 text-right">
            </div>
            <div className="col-sm-12 col-md-12 col-lg-1 col-xl-1  mb-3 mb-lg-0 p-0 text-right">
            </div>
          </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder ">
                  <div className="details-img d-flex flex-column d-md-block">
                    {campaignData && campaignData.IsPrivacy ?
                      <div className="featured-image campaign-image-text">
                        <h2 class="title black-color">{getLetterInitials(campaignData.RequesterName)}</h2>
                      </div> :
                      campaignData && campaignData.IsVideoAsCampaignImage ?
                        campaignData && parseInt(campaignData.VideoLink) ? (
                          <Vimeo video={campaignData.VideoLink} width="285"  />
                        ) : <img
                          className="user img-profile"
                          src={
                            campaignData && campaignData.ImageLink
                              ? campaignData.ImageLink
                              : "assets/images/logo.png"
                          }
                          alt=""
                        />
                        :
                        <img
                          className="user img-profile"
                          src={
                            campaignData && campaignData.ImageLink
                              ? campaignData.ImageLink
                              : "assets/images/logo.png"
                          }
                          alt=""
                        />}

                 
                    <div className="mb-20 mt-10 ">
                      <div className={"row ml-5"}>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 px-0 d-flex align-items-center">
                          <p className="mb-2 mr-4">
                            <strong> Share Story </strong>
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 d-flex align-items-center mr-3">
                          <ul className="footer_social">
                            <li>
                              <FacebookShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-facebook" />} />
                            </li>
                            <li>
                              <TwitterShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-twitter" />} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                      <table className="table custom-table mb-4">
                        <thead>
                          <tr>
                            <th scope="col">Summary</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                        {campaignData && campaignData.Resources ? (
                            campaignData.Resources.map((item, index) => {
                              // // debugger
                              return(
                              <tr key={item.Id}>
                                <td>  
                                  <ProgressBar  index={index} bgcolor={"black"} name={item.Name} totalNo={item.Quantity} pendingNo={item.PendingQuantity} isCash={item.IsCash} />
                                </td>
                              </tr>
                              )}))
                        :null}
                      
                        </tbody>
                      </table>
                </div>

              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Recipient:</h4>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                        <li className={campaignData && getRecipientName(campaignData.RequesterName,campaignData.RequesterUserName,campaignData.IsPrivacy,campaignData.IsRecipientAnonymous)[1] ? "initial-text" : ""}>
                          {campaignData&&getRecipientName(campaignData.RequesterName,campaignData.RequesterUserName,campaignData.IsPrivacy,campaignData.IsRecipientAnonymous)[0]}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Story:</h4>
                      </p>
                      <ul className="dash-check-list resources-list story-text">
                        <li>
                          {campaignData?.Story}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-7 col-lg-12 col-xl-12">
                    <div className="video-hloder">
                     {campaignData && parseInt(campaignData.VideoLink) && !campaignData.IsVideoAsCampaignImage ? (
                        <Vimeo video={campaignData.VideoLink} width="450" />
                      ) : null}
                    </div>
                  </div>
                  {campaignData.OtherFiles && campaignData.OtherFiles.length ? <div className="col-12 col-md-7 col-lg-10 col-xl-10 my-4">
                    <hr />
                    <h4 className="mb-4">Files</h4>
                    {campaignData.OtherFiles
                      ? campaignData.OtherFiles.map((file, index) => (
                        <div
                          key={index}
                          className={"file-list d-flex align-items-center justify-content-between"}
                        >
                          <p className="m-0">{file ? file.Title : ""}</p>
                          <a
                            className="btn site-btn text-white"
                            href={file && file.FilePath}
                            target="_blank"
                            rel="noreferrer"
                            download={file ? file.Title : ""}
                          >
                            View
                              </a>
                        </div>
                      ))
                      : null}
                  </div> : null}
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                    <hr />
                    <div className="d-flex justify-content-between mb-3">
                      <h4 className="mb-4"> Resources needed </h4>
                      {showDonateBtn && <a
                        className="btn site-btn text-nowrap align-items-center d-flex text-white"
                        onClick={() => { handleDonate(); }}>
                        Donate
                      </a>}
                    </div>
                    <div className="table-responsive">
                      <table className="table custom-table mb-4">
                        <thead>
                          <tr>
                            <th scope="col">Select</th>
                            <th>Resource Name</th>
                            <th>Amount Needed</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Donation amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campaignData && campaignData.Resources ? (
                            campaignData.Resources.map((item, index) => (
                              <tr key={item.Id}>
                                <td>
                                  {!item.IsCash && <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id={item.Id} onChange={(e) => { handleSelection(e, index) }} disabled={item.Status !== 2} />
                                  </div>}
                                </td>
                                <td>
                                  <p>{item.Name}</p>
                                </td>
                                <td>
                                  {(item.IsCash === false && item.isSelected) ? <select
                                    className="from-control quantityDropdown"
                                    value={item.selectedQuantity}
                                    onChange={(e) => { handleQuantityChange(e.target.value, index) }} >
                                    {Array.from(Array(item.PendingQuantity).keys()).map(count => (<option key={count} value={count + 1}>{count + 1}</option>))}
                                  </select> : <p>{item.IsCash && '$'}{item.PendingQuantity}</p>}
                                </td>
                                <td>
                                  <p>{item.Description}</p>
                                </td>
                                <td><p>{item.IsCash === true ? 'Cash' : 'Non cash'}</p></td>
                                <td><p>{RESOURCE_STATUS.find((status) => status.id === item.Status).text || 'N/A'}</p></td>
                                <td>
                                  {item.IsCash ? <div className="dropdown text-center">
                                  <div className="input-group mb-2"  style={{display: (isCommited ? "none" : "")}}>
                                      <label className="check-style">
                                        Anonymous
                                      <input
                                          type="checkbox"
                                       checked={isAnonymous}
                                       data-toggle="modal" data-target={isAnonymous&&"#exampleModal3"}
                                       onChange={()=>{handleIsAnonymous()}}
                                          id="IsAnonymous"
                                          name="IsAnonymous"
                                          disabled={item.Status !== 2}
                                        />
                                      <span className="checkmark"></span>
                                      </label>
                                   </div>
                                    <div className="input-group mb-2" style={{display: (isCommited ? "none" : "")}}>
                                      <span className="input-group-text" id="basic-addon1" 
                                      style={{position: "absolute", zIndex: "20",backgroundColor: "transparent", border: "none"}}>$</span>
                                      <input type="number" className="form-control" aria-describedby="basic-addon1"
                                      name="amount"
                                      placeholder="Amount"
                                      // disabled={item.Status !== 2}
                                      required=""
                                      style={{ borderWidth: 1,
                                         borderColor: 'rgb(192 192 192 / 37%)',
                                          width: 80,
                                          paddingLeft: "1.5rem"}}
                                      onChange={(event) => { setDonatAmount(event.target.value) }} />
                                    </div>
                                 
                                    {/* <StripeCheckout
  name="Every Human Needs" // the pop-in header title
  description="Donation For Cause" // the pop-in header subtitle
  image="http://localhost:3000/assets/images/logo.png" // the pop-in header image (default none)
  // ComponentClass="div"
  panelLabel="Give Money" // prepended to the amount in the bottom pay button
  amount={10000} // cents
  currency="USD"
  // stripeKey="..."
  locale="us"
  email="manish@ost.agency"
  // // Note: Enabling either address option will give the user the ability to
  // // fill out both. Addresses are sent as a second parameter in the token callback.
  // shippingAddress
  // billingAddress={false}
  // // Note: enabling both zipCode checks and billing or shipping address will
  // // cause zipCheck to be pulled from billing address (set to shipping if none provided).
  // zipCode={false}
  // alipay // accept Alipay (default false)
  // bitcoin // accept Bitcoins (default false)
  // allowRememberMe // "Remember Me" option (default true)
  // token={this.onToken} // submit callback
  // opened={this.onOpened} // called when the checkout popin is opened (no IE6/7)
  // closed={this.onClosed} // called when the checkout popin is closed (no IE6/7)
  // // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason
  // // you are using multiple stripe keys
  // reconfigureOnUpdate={false}
  // // Note: you can change the event to `onTouchTap`, `onClick`, `onTouchStart`
  // // useful if you're using React-Tap-Event-Plugin
  // triggerEvent="onTouchTap"
  >
  <button
                                      className="btn chips-box mb-2"
                                      id="dropdownMenuButton"
                                      // data-toggle={requestorPaymentOptions.length > 0 && "dropdown"}
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      // data-toggle="modal"
                                      // data-target={!!requestorPaymentOptions.length && "#exampleModal2"}
                                      disabled={(item.Status !== 2 || donatAmount <= 0 || item.PendingQuantity < donatAmount || isCommited)}
                                      onClick={() => userPayents(item.Id)}
                                      style={{margin: "0rem .5rem"}}
                                    >
                                      <span>{ isCommited ? "Commited" : "Commit" }</span>
  </button>
</StripeCheckout> */}
                                    <button
                                      className="btn chips-box mb-2"
                                      // disabled={(item.Status !== 2 || donatAmount <= 0 || item.PendingQuantity < donatAmount || isCommited)}
                                      onClick={() => userPayents(item.Id)}
                                      style={{margin: "0rem .5rem"}}
                                    >
                                      <span>{ isCommited ? "Donated" : "Donate" }</span>
                                    </button>
                                    {/* <button
                                      className="btn chips-box mb-2"
                                      id="dropdownMenuButton"
                                      data-toggle={requestorPaymentOptions.length > 0 && "dropdown"}
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      data-toggle="modal"
                                      data-target={!!requestorPaymentOptions.length && "#exampleModal2"}
                                      disabled={(item.Status !== 2 || donatAmount <= 0 || item.PendingQuantity < donatAmount || isCommited)}
                                      onClick={() => userPayents(item.Id)}
                                      style={{margin: "0rem .5rem"}}
                                    >
                                      <span>{ isCommited ? "Commited" : "Commit" }</span>
                                      {"Pay $" + item.Quantity}
                                    </button> */}
                                    {(requestorPaymentOptions && !!requestorPaymentOptions.length) ? <div
                                      className="dropdown-menu dropdown-menu-right"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {requestorPaymentOptions.map((item, index) => (
                                        <button
                                          key={index}
                                          className="dropdown-item"
                                          onClick={() => { window.open(item.PaymentUrl, '_blank'); }}
                                        >
                                          {item.PaymentPlatformName}1
                                        </button>
                                      ))}
                                    </div> : null}
                                  </div> : <div  style={{textAlign: 'center'}}><strong> - </strong></div>}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td />
                              <td>No Resources Found</td>
                              <td />
                              <td />
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {chatList && chatList?.length !== 0 && <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3 px-0">
                      <hr />
                      {/* <div class='mt-4 d-flex justify-content-start align-items-center'>
                        <h4 className="m-0 mr-3"> Messages </h4>
                        <small><b>Tips:</b></small>
                        <button type="button" class="btn table-sitebtn no-wrap small-tip-btn ml-2" data-toggle="modal" data-target="#exampleModal">
                          <i className="fa fa-info" /> 
                        </button>
                      </div> */}
                      <Accordion allowZeroExpanded onChange={onAccordinChange}>
                        {chatList && chatList.map((item, index) => (
                          <AccordionItem key={item.Id} uuid={item.Id} dangerouslySetExpanded={currentId === item.Id}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="msg-head">
                                  <div className="media align-items-center">
                                    <div className="media-body" >
                                      <h4 className="my-0">{item.Name}</h4>
                                    </div>
                                    <div className="dropdown mr-3">
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      />
                                      <div
                                        className="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a data-toggle="modal" data-target="#exampleModal" className="dropdown-item" onClick={() => setFeedback(item)}>
                                          Feedback
                                          </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel style={{ borderWidth: 1 }}  >
                              <div className="col-lg-12 col-md-7 col-sm-12 mesgs" >
                                {chatUsers && chatDataMsg && chatDataMsg.map((item, index) => {
                                  return (
                                    <div className="msg_history" key={index}>
                                      { myUserID !== item.SenderId ? <div className="incoming_msg">
                                        <div class="incoming_msg_img"> <img src={chatUsers.find((user) => user.Id === item.SenderId).ProfilePic} alt="profile" /> </div>
                                        <div className="received_msg">
                                          <div className="received_withd_msg">
                                            <p>
                                              {item.Message}
                                            </p>
                                            <span className="time_date"> {timeSince(new Date(item.SentOn.concat('Z')))}</span>
                                          </div>
                                        </div>
                                      </div> : null}
                                      { myUserID === item.SenderId ? <div className="outgoing_msg">
                                        <div className="sent_msg position-relative">
                                          <div className="sent_msg_img"> <img src={chatUsers.find((user) => user.Id === item.SenderId).ProfilePic} alt="profile" /> </div>
                                          <div className="sent-msg-ctnr">
                                            <p> {item.Message}</p>
                                            <span className="time_date"> {timeSince(new Date(item.SentOn.concat('Z')))}</span>
                                          </div>
                                        </div>
                                      </div> : null}
                                    </div>
                                  )
                                }).reverse()}
                                {(!chatDataMsg) ? <div style={{ height: 100, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>{chatMsg}</div> : null}
                                {!chatDataMsg && <div className="mt-3"><small class='p-3 text-secondary'>To get started, send a message to the requester</small></div>}
                                <div className="type_msg">
                                  <div className="input_msg_write" id={`messageBoxForScroll${item.Id}`}>
                                    <input
                                      type="text"
                                      className="write_msg"
                                      placeholder="Type a message"
                                      onChange={onTextChange}
                                      value={sendMsgText || ""}
                                    />
                                    <button className="msg_send_btn mr-4" type="button" onClick={() => { onSendBtnTap(); }}>
                                      <i
                                        className="fa fa-paper-plane-o"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Feedback</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="w-100">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      feedbackMessage: ""
                    }}
                    validationSchema={Yup.object().shape({
                      feedbackMessage: Yup.string().required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      feedback['FeedbackText'] = values.feedbackMessage;
                      sendFeedbackMessage(feedback)
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
                      <Form>
                        <input
                          className="w-90 feedback-input"
                          type="text"
                          placeholder="Enter feedback"
                          id="feedbackMessage"
                          name="feedbackMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required=""
                          defaultValue={""}
                        />
                        <span className="text-danger">{errors.feedbackMessage &&
                          touched.feedbackMessage &&
                          errors.feedbackMessage}</span>
                        <div className="modal-footer">
                          <input
                            className="btn btn-secondary"
                            type="submit"
                            value="Send feedback"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAPIRequest(DonorCampaignDetails);