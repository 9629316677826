// export const BASE_URL = 'https://everyhumanneeds.com:1111/api'; // live
export const BASE_URL = 'http://18.225.32.241:81/api'; // staging
// export const BASE_URL = 'http://127.0.0.1:8080/api'; // local

export const API = {
    // Auth
    LOGIN: `${BASE_URL}/Auth/login`,

    // Auth
    REGISTER_USER: `${BASE_URL}/Auth/RegisterUser`,
    FORGOT_PASSWORD: `${BASE_URL}/Auth/ForgotPassword`,
    RESET_PASSWORD: `${BASE_URL}/Auth/ResetPassword`,
    VERIFY_TOKEN: `${BASE_URL}/Auth/VerifyEmail?code=`,

    //Donor
    REGISTER_DONOR:`${BASE_URL}/Auth/RegisterUser`,
    DONOR_REQUESTER_ALL_CAMPAIGN: `${BASE_URL}/Donor/GetDonorsInRequesterAllCampaigns?RequesterId=`,
    DONOR_CAMPAIGN: `${BASE_URL}/Donor/GetDonorDetails`,
    SAVE_FAVOURITE_CAMPAIGN: `${BASE_URL}/Donor/SaveFavouriteCampaign`,
    GET_FAVOURITE_CAMPAIGN: `${BASE_URL}/Donor/GetFavoriteCampaign`,
    GET_DONATED_CAMPAIGN_DETAIL: `${BASE_URL}/Donor/GetDonatedCampaignDetail?token=`,
    CONFIRM_DONAR_DONATION: `${BASE_URL}/Donor/ConfirmDonorDonation`,
    STRIPE_PAYMENT:`${BASE_URL}/Payment/CreateCheckoutSession`,
    STRIPE_PAYMENT_SUCCESS:`${BASE_URL}/Payment/RetrieveCheckoutSession`,

    // Settings
    CHANGE_PASSWORD: `${BASE_URL}/Settings/ChangePassword`,
    CHANGE_EMAIL: `${BASE_URL}/Settings/ChangeEmail`,
    GET_USER_PROFILE: `${BASE_URL}/Settings/GetUserProfile`,
    UPDATE_PROFILE_PIC: `${BASE_URL}/Settings/UpdateProfilePic`,

    // Admin
    REQUESTERS: `${BASE_URL}/Admin/GetRequesters`,
    REQUESTER_DETAILS: `${BASE_URL}/Requester/GetRequesterDetails?userId=`,
    DONORS: `${BASE_URL}/Admin/GetDonors`,
    DELETE_DONORS: `${BASE_URL}/Admin/DeleteDonor`,
    CAMPAIGN_SUMMARY: `${BASE_URL}/Admin/GetCampaignSummary`,
    REGISTER_REQUESTER: `${BASE_URL}/Admin/RegisterRequester`,
    DONOR_DETAILS: `${BASE_URL}/Donor/GetDonorDetails?userId=`,
    RESOURCES: `${BASE_URL}/Admin/GetAllCampaignResources?PageIndex=`,
    UPDATE_USER_STATUS: `${BASE_URL}/Admin/UpdateUserStatus`,
    USERS_LIST: `${BASE_URL}/Admin/GetUsers?roleId=`,
    UPDATE_ADMIN: `${BASE_URL}/Admin/UpdateUser`,
    REGISTER_ADMIN: `${BASE_URL}/Auth/RegisterUser`,
    REGISTER_ADMIN_V1: `${BASE_URL}/Admin/RegisterUser`,
    RESEND_REQUESTER_INVITATION: `${BASE_URL}/Admin/ResendRequesterInvitation`,
    CAMPAIGN_RESOURCES_OPEN_SUMMARY: `${BASE_URL}/Admin/GetOpenCampaignResourcesSummary`,
    CAMPAIGN_RESOURCES_SUMMARY: `${BASE_URL}/Admin/GetCampaignResourcesSummary`,
    CAMPAIGN_RESOURCES_DONATION_SUMMARY: `${BASE_URL}/Admin/GetCampaignResourcesDonationSummary`,
    CAMPAIGN_RESOURCES_DONOR_DETAILS: `${BASE_URL}/Admin/GetCampaignResourcesDonorDetails`,

    // Campaign
    CAMPAIGNS: `${BASE_URL}/Campaign/GetCampaigns`,
    SAVE_CAMPAIGN: `${BASE_URL}/Campaign/SaveCampaign`,
    CAMPAIGN_DETAILS: `${BASE_URL}/Campaign/GetCampaignById?campaignId=`,
    GET_CHAT_LIST: `${BASE_URL}/Chat/GetChatList?campaignId=`,
    GET_CHAT_MESSAGES: `${BASE_URL}/Chat/GetChatMessages?ChatId=`,
    CHAT_START: `${BASE_URL}/Chat/Start`,
    NEW_MESSAGE: `${BASE_URL}/Chat/NewMessage`,
    CAMPAIGN_RESOURCE_STATUS_UPDATE: `${BASE_URL}/CampaignResource/UpdateStatus`,
    UPDATE_PENDING_QUANTITY: `${BASE_URL}/CampaignResource/UpdatePendingQuantity`,
    SEND_FEEDBACK_MESSAGE: `${BASE_URL}/FeedBack/FeedbackCreate`,
    DONATE_WITHOUT_CHAT: `${BASE_URL}/Chat/DonateWithoutChat`,

    // Generic
    FILE_UPLOAD: `${BASE_URL}/Generic/FileUpload`,
    PUBLIC_FEATURED_CAMPAIGNS: `${BASE_URL}/Public/GetFeaturedCampaigns`,
    PUBLIC_LATEST_CAMPAIGNS: `${BASE_URL}/Public/GetCampaigns`,
    PUBLIC_CONTACT_US: `${BASE_URL}/Public/ContactUs`,
    PUBLIC_CAMPAIGN_DETAILS: `${BASE_URL}/Public/GetCampaignById?campaignId=`,
    PAYMENT_OPTIONS_TYPES: `${BASE_URL}/Settings/GetPaymentPlatformTypes`,
    PAYMENT_OPTION_SAVE: `${BASE_URL}/Settings/SaveUserPaymentOptions`,
    USER_PAYMENT_OPTIONS_REQUESTOR: `${BASE_URL}/Settings/GetUserPaymentOptions`,
    USER_PAYMENT_OPTIONS: `${BASE_URL}/Settings/GetUserPaymentOptions?userId=`,
    REQUESTERS_GREETINGS: `${BASE_URL}/Requester/SendThankYouNote`,
    USER_BY_ID : `${BASE_URL}/Admin/GetUserById?userId=`,
    CAMPAIGN_UNIQUE_RESOURCES : `${ BASE_URL }/CampaignResource/GetUniqueCampaignResourceNames`,
    GET_LATEST_VERSION : `${BASE_URL}/Public/GetLatestVersion`,
}

export const AXIOS_METHOD_TYPES = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
}

export const CAMPAIGN_STATUS = [
    { id: 1, text: "Needs Approval" },
    { id: 2, text: "Approved" },
    { id: 4, text: "Completed" },
    { id: 0, text: "Featured" },
]

export const USER_STATUS = [
    { id: 2, text: "Active" },
    { id: 4, text: "InActive" },
]

export const RESOURCE_STATUS = [
    { id: 1, text: "Close" },
    { id: 2, text: "Open" },
    { id: 3, text: "Pending" },
]

export const USER_ROLE = [
    { id : 2 , text : "Church Admin" } ,
    { id : 1 , text : "Super Admin" } ,
    { id : 3 , text : "Donor" } ,
    { id : 4 , text : "Requester" } ]

export const TIPS = `
<b>Requester Communication</b>:<br/>
Keep all your communication about transactions on this site. Beware of those who ask you to communicate with you outside of this site.
Do not give out personal information, even for shipping.

<br/>
<br/>


<b>Cash options</b>:<br/>
Do not send cash back to donors.
Make sure your donation handle is correct.

<br/>
<br/>

<b>Safe location</b>:<br/>
Usually, the Requester suggests where to meet. If it’s a small item, we recommend meeting at a Community MeetUp Spot or other well-lit, public location, like a grocery store or cafe.

<br/>
<br/>

<b>Donator</b>:<br/>
Never give more than the listed need.
Do not expect the Requester to return any donation.

<br/>
<br/>

<b>Everyone Beware of scams</b>:<br/>
The other person sends you a link that requires you to log in to get your donation.
<br/>Scammers may create fake websites that look like EveryHumanNeeds and ask you to log in with your email and password. 
<br/>Never click on these.
When you use EveryHumanNeeds messaging system, you never need to give out your email address or phone number. Giving out personal information is how fraud and identity theft can start.
<br/>The person or the post tells you about an "investment opportunity" or an "easy way to make money" by sending money.`
