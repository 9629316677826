/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { useParams } from "react-router";
import { GlobalContext } from "../../../context/GlobalContext";
import { API, AXIOS_METHOD_TYPES, RESOURCE_STATUS, TIPS } from "../../../configs/constants";
import Vimeo from "@u-wave/react-vimeo";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { timeSince, getCampaignShareableURL, getLetterInitials } from '../../../configs/utility';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { getRecipientName } from '../../../configs/utility';
import moment from 'moment';
import "./DonationConfirmationCampaign.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Meta from "../../common/meta/Meta";
import ProgressBar from '../../../components/progressBar/ProgressBar'
let isAccordionClose;
let currentAccordienID;
let timeoutTemp;

const DonationConfirmationCampaign = (props) => {

  const globalContext = useContext(GlobalContext);
  const [campaignData, setCampaignData] = useState(null);
  const [chatList, setChatList] = useState(null);
  const { id } = useParams();
  const [showDonateBtn, setShowDonateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [chatDataMsg, setChatDataMsg] = useState(null);
  const [sendMsgText, setSendMsgText] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [chatMsg, setChatMsg] = useState("Loading...");
  const [requestorPaymentOptions, setRequestorPaymentOptions] = useState([]);
  const myUserID = globalContext.user.User.Id;
  const [chatUsers, setChatUsers] = useState(null);
  const [donatAmount, setDonatAmount] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isCommited, SetIsCommited] = useState(false);
  const [favoriteCompaigns, setFavoriteCompaign] = useState(false);
  const [donatedDetail, setDonatedDetail] = useState({});

  useEffect(() => {
    getDonatedCampaignDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getDonatedCampaignDetails = () => {
    debugger
    let serviceParams = {
      api: `${API.GET_DONATED_CAMPAIGN_DETAIL}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);

    props.commonAPIRequest(serviceParams, (result) => {
      if (result && result.Status.StatusId === 1) {
        debugger
        setDonatedDetail(result.Data);
        if (result.Data.length && result.Data[0].CampaignId) {
          getCampaignDetails(result.Data[0].CampaignId)
        }
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: result ? result.Status.Message : "No data found",
      });
    });
  };

  const getCampaignDetails = (campaingId) => {
    let serviceParams = {
      api: `${API.CAMPAIGN_DETAILS}${campaingId}`,
      method: AXIOS_METHOD_TYPES.GET,
    };


    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        result.Data.Resources.map((item) => { item.selectedQuantity = item.PendingQuantity; return null; });
        setCampaignData(result.Data);
        setTimeout(() => { }, 500);
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: result ? result.Status.Message : "No data found",
      });
    });
  };


  const handleDonationConfirm = (id, quantity) => {
    let _resources = [];

    let serviceParams = {
      api: API.CONFIRM_DONAR_DONATION,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        "Id": id,
        "Quantity": quantity
      },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      getDonatedCampaignDetails();
      if (result && result.Status.StatusId === 1) {
        
        globalContext.setNotifications({
          type: "success",
          text: result ? result.Status.Message : "Donation Confirmed.",
        });
      } else {
       
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Error in confirming Donation.",
        });
      }
    });
  }

  const handleQuantityChange = (quantity, index) => {
    debugger
    let _data = JSON.parse(JSON.stringify(donatedDetail));
    _data[index].Quantity = parseInt(quantity);
    setDonatedDetail(_data);
  }


  return (
    <div className="container campaign-details-container donor-camp-details">
      <Meta title={"Donor-Campaign-details"} content={"Donor campaign detail"} />
      {campaignData&&donatedDetail&&globalContext.user.User.Id &&donatedDetail.length&&donatedDetail[0].DonorId==globalContext.user.User.Id && <div className="row">
        <div className="col-12">

          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">EveryHumanNeeds Use Tips</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div dangerouslySetInnerHTML={{ __html: TIPS }}></div>
                </div>

              </div>
            </div>
          </div>

          <div className="form-div details-page-holder  pt-30">
            <div className="row ">

              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3 mb-lg-0">
                <h3 className="dash-heading mb-0">{campaignData.Name}</h3>
              </div>

              <div className={"col-sm-12 col-md-7 col-lg-7 col-xl-7"}>
                <div className={"row ml-5"}>
                  {/* Campaign Status */}
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex  p-0 mb-4 mb-lg-0">
                    <div className='row d-flex align-items-center w-100'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-5 no-wrap m-0"> <strong>Campaign Status:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <label className={`m-0 chips-box-btn no-wrap ${campaignData && campaignData.Status === 2 ? "grn-bg" : ""}`} > {campaignData && campaignData.StatusName} </label>
                      </div>
                    </div>
                  </div>

                  {/* Campaign End Date */}
                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex mb-3 mb-lg-0 p-0">
                    <div className='row d-flex align-items-center'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-2 no-wrap m-0"> <strong>End date:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="day-text font-weight-normal m-0"> {moment(new Date(campaignData.EndDate)).format('MM/DD/YYYY')}</p>
                      </div>
                    </div>
                  </div>

                  {/* Tips */}
                  <div class='col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex mb-3 mb-lg-0 p-0'>
                    <div className='row d-flex align-items-center'>
                      <p className="m-0 mr-2"><b>Tips:</b></p>
                      <button type="button" class="btn table-sitebtn no-wrap tips-btn " data-toggle="modal" data-target="#exampleModal"> <i className="fa fa-info" /> </button>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div className="row ">
              <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11  mb-3 mb-lg-0 p-0 text-right">
              </div>
              <div className="col-sm-12 col-md-12 col-lg-1 col-xl-1  mb-3 mb-lg-0 p-0 text-right">
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder ">
                  <div className="details-img d-flex flex-column d-md-block">
                    {campaignData && campaignData.IsPrivacy ?
                      <div className="featured-image featured-image-text">
                        <h2 class="title black-color">{getLetterInitials(campaignData.RequesterName)}</h2>
                      </div> :
                      campaignData && campaignData.IsVideoAsCampaignImage ?
                        campaignData && parseInt(campaignData.VideoLink) ? (
                          <Vimeo video={campaignData.VideoLink} width="285" />
                        ) : <img
                          className="user img-profile"
                          src={
                            campaignData && campaignData.ImageLink
                              ? campaignData.ImageLink
                              : "assets/images/logo.png"
                          }
                          alt=""
                        />
                        :
                        <img
                          className="user img-profile"
                          src={
                            campaignData && campaignData.ImageLink
                              ? campaignData.ImageLink
                              : "assets/images/logo.png"
                          }
                          alt=""
                        />}


                    <div className="mb-20 mt-10 ">
                      <div className={"row ml-5"}>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 px-0 d-flex align-items-center">
                          <p className="mb-2 mr-4">
                            <strong> Share Story </strong>
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 d-flex align-items-center mr-3">
                          <ul className="footer_social">
                            <li>
                              <FacebookShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-facebook" />} />
                            </li>
                            <li>
                              <TwitterShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-twitter" />} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Recipient:</h4>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                        <li className={campaignData && getRecipientName(campaignData.RequesterName, campaignData.RequesterUserName, campaignData.IsPrivacy, campaignData.IsRecipientAnonymous)[1] ? "initial-text" : ""}>
                          {campaignData && getRecipientName(campaignData.RequesterName, campaignData.RequesterUserName, campaignData.IsPrivacy, campaignData.IsRecipientAnonymous)[0]}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*<div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Campaign Story:</h4>
                      </p>
                      <ul className="dash-check-list resources-list story-text">
                        <li>
                          {campaignData?.Story}
                        </li>
                      </ul>
                    </div>
                              </div>*/}
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap ">

                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                       
                          {donatedDetail && donatedDetail.length?
                          <li> Thank you for your commitment and generious donation for <strong>{campaignData.Name}</strong> campaign. </li>:null}
                         
                       
                      </ul>
                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                       
                      {donatedDetail && donatedDetail.length&& !donatedDetail.find(x=>x.IsConfirmed)?
                       <li> Please confirm your donation. </li>:null}
                    
                   </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-11 col-xl-10 mt-3">
                    <hr />

                    <div className="table-responsive">
                      <table className="table custom-table mb-4">
                        <thead>
                          <tr>

                            <th>Resource Name</th>
                            <th>Donated Quantity</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {donatedDetail && donatedDetail.length ? (

                            donatedDetail.map((item, index) => {
                              debugger
                              var resourceDetail = campaignData && campaignData.Resources ? campaignData.Resources.find(x => x.Id == item.CampaignResourceId) : null;
                              if (resourceDetail) {
                                return (

                                  <tr key={item.ChatId}>

                                    <td>
                                      <p>{resourceDetail.Name}</p>
                                    </td>

                                    <td>
                                      {(resourceDetail.IsCash === false ?
                                        !item.IsConfirmed?
                                        <select
                                          className="from-control quantityDropdown"
                                          value={item.Quantity}
                                          onChange={(event) => {
                                            debugger
                                            handleQuantityChange(event.target.value,index);
                                          }} >
                                          {Array.from(Array(item.Quantity).keys()).map(count => (<option key={count} value={count + 1}>{count + 1}</option>))}
                                        </select>:item.Quantity
                                        :
                                        !item.IsConfirmed?
                                        <>
                                          <div className="input-group mb-2">
                                            <span className="input-group-text" id="basic-addon1"
                                              style={{ position: "absolute", zIndex: "20", backgroundColor: "transparent", border: "none" }}>$</span>
                                            <input type="text" className="form-control" aria-describedby="basic-addon1"
                                              type="number"
                                              name="amount"
                                              value={item.Quantity}
                                              required=""
                                              style={{
                                                borderWidth: 1,
                                                borderColor: 'rgb(192 192 192 / 37%)',
                                                width: 80,
                                                paddingLeft: "1.5rem"
                                              }}
                                              onChange={(event) => { 
                                                debugger
                                                handleQuantityChange(event.target.value,index);
                                                 }} />

                                          </div>
                                        </>:'$'+item.Quantity
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className="btn chips-box mb-2"
                                        id="dropdownMenuButton"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-toggle="modal"
                                        data-target={!!requestorPaymentOptions.length && "#exampleModal2"}
                                        disabled={item.IsConfirmed?true:false}
                                        onClick={() => {
                                          handleDonationConfirm(item.ChatResourceId, item.Quantity)
                                        }}
                                        style={{ margin: "0rem .5rem" }}
                                      >
                                        <span>{item.IsConfirmed?'Confirmed':'Confirm'}</span>

                                      </button>
                                    </td>


                                  </tr>
                                )
                              }

                            }
                            )
                          ) : (
                            <tr>
                              <td />
                              <td>No Resources Found</td>
                              <td />
                              <td />
                            </tr>
                          )}

                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

    </div>
  );
}

export default withAPIRequest(DonationConfirmationCampaign);